<template>
  <div v-if="isWx" style="height: 100vh;background: #000;">
    <img src="./assets/weixin.jpeg" style="width: 100vw;" />
  </div>
  <div v-if="showWebclip">
    <Webclip :exit="onExitWebclip" :download="onDownloadWebclip" />
  </div>
  <div v-if="!showWebclip" class="download">
    <div>
      <Ios v-if="isIOS" :showWebclip="onShowWebClip" />
      <Android v-else />
    </div>
  </div>
  <van-overlay :show="showSafari" z-index="99999">
    <div class="safariAlert">
      <img src="./assets/safari.png" class="safari" />
      <div class="containeralert">
        <div class="safariInfo">
          <div class="address">
            <p>{{ address }}</p>
          </div>
          <img class="copy" @click="onCopy" src="./assets/button.png" />
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { showDialog, showToast } from 'vant';
import Android from './components/Android.vue';
import Ios from './components/Ios.vue';
import Webclip from './components/Webclip.vue';
import { ref } from 'vue';
import OpenInstall from "OpenInstall";

export default {
  name: 'App',
  components: {
    Android,
    Ios,
    Webclip
  },
  data() {

    return { configData: null, channel: process.env.VUE_APP_CHANNEL, showSafari: false, address: window.location.href }
  },
  setup() {
    document.title = 'JalaLive';

    const isiPad = () => {
      return (/macintosh|mac os x/i.test(navigator.userAgent) && window.screen.height > window.screen.width && !navigator.userAgent.match(/(iPhone\sOS)\s([\d_]+)/)) || navigator.userAgent.match(/(iPad).*OS\s([\d_]+)/);
    }

    const showWebclip = ref(false);
    var ua = window.navigator.userAgent;
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent) || isiPad();
    const isWx = ua.match(/MicroMessenger/i) == 'micromessenger';
    const onExitWebclip = () => {
      showWebclip.value = false;
    }
    const onShowWebClip = () => {
      showWebclip.value = true;
    }
    return { isIOS, showWebclip, isWx, onExitWebclip, onShowWebClip }
  },
  mounted() {
    this.channel = this.getQueryVariable("channel") || this.channel;
    console.log("this.channel", this.channel)
    this.loadConfig();
  },
  methods: {
    onCopy() {
      let str = window.location.href;
      const oInput = document.createElement("input");
      oInput.value = str;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.remove();
      showToast("Copy Success");
      this.showSafari = false;
    },
    isiPad() {
      return (/macintosh|mac os x/i.test(navigator.userAgent) && window.screen.height > window.screen.width && !navigator.userAgent.match(/(iPhone\sOS)\s([\d_]+)/)) || navigator.userAgent.match(/(iPad).*OS\s([\d_]+)/);
    },
    isIosDevice() {
      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent) || this.isiPad();
      return isIOS;
    },

    async loadConfig() {
      try {
        const res = await fetch('config.json');
        if (res.ok) {
          let data = await res.json();
          if (data) {
            data.forEach(element => {
              if (element.channel == this.channel) {
                this.configData = element;
                if (this.isIosDevice()) {
                  if (element.appkey) { // ios
                    this.registerOpenInstall(element.appkey);
                  } else {
                    this.initDownload();
                  }
                } else {
                  if (element.appkeyandroid) {// android
                    this.registerOpenInstall(element.appkey);
                  } else {
                    this.initDownload();
                  }
                }
              }
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    initDownload() {
      var button = document.getElementById("button");//为button对象绑定对应id的元素
      var install = this;
      button.onclick = () => {
        console.log("点击了按钮111");
        install.onDownload();
      }
    },

    async registerOpenInstall(appkey) {
      console.log("registerOpenInstall", appkey)
      //var m = this;
      var data = OpenInstall.parseUrlParams();///openinstall.js中提供的api，解析当前网页url中的查询参数并对data进行赋值
      data.channel = this.channel;
      // data.subchannel = this.subchannel;
      console.log("获取游览器参数", data);
      new OpenInstall({//初始化方法，与openinstall服务器交互，应尽早调用
        appKey: appkey,//appKey为openinstall为应用分配的唯一id（必须传入）
        onready: function () {//初始化成功回调方法。当初始化完成后，会自动进入
          this.schemeWakeup();//尝试使用scheme打开App（主要用于Android以及iOS的QQ环境中）
          var button = document.getElementById("button");//为button对象绑定对应id的元素
          var install = this;
          button.onclick = () => {
            console.log("点击了按钮");
            //if(m.isIOS){
            install.wakeupOrInstall();//此方法为scheme、Universal Link唤醒以及引导下载的作用（必须调用且不可额外自行跳转下载）
            //}else{
            //  m.onDownload();
            //}
            return false;
          }
        }
      }, data);//初始化时传入data，作为一键拉起/App传参安装时候的参数
    },

    async onDownload() {
      if (this.isIOS) {
        window.location.href = this.configData.tfios;
      } else {
        window.location.href = this.configData.android;
      }
    },

    getiOSVersion() {
      const match = navigator.userAgent.match(/(iPhone|iPad|iPod).*?OS (\d+[._]\d+)/i);
      if (match) {
        const version = match[2].replace('_', '.');
        return parseFloat(version);
      }
      return null;
    },
    async onDownloadWebclip() {
      const isSf = () => {
        const ua = navigator.userAgent.toLowerCase();
        return /safari|applewebkit/i.test(ua) && (!/chrome|crios|crmo/i.test(ua) && !/chrome|gsa|crmo/i.test(ua));
      }
      let isSafari = isSf();
      if (!isSafari) {
        this.showSafari = true;
        return;
      }
      let data = this.configData;
      window.location.href = data.webclip;
      const u = this.getiOSVersion();
      if (u > 17.0) {
        setTimeout(() => {
          showDialog({
            title: '温馨提示',
            message: '下载已经完成，请按照教程安装',
          }).then(() => { }).catch(() => { });
        }, 3000);
        return;
      }
      let i = 0;
      let t1 = setInterval(() => {
        iosConfig()
      }, 3000)
      function iosConfig() {
        if (i > 0) {
          clearInterval(t1)
        }
        i++;
        window.location.href = data.setup;
      }
    },
    getQueryVariable(variable) {
      if (window.location.search && window.location.search != "") {
        let query = window.location.search.substring(1);
        if (query.indexOf("?") != -1) {
          query = query.substr(query.indexOf("?") + 1);
        }

        let vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
          let pair = vars[i].split("=");
          if (pair[0] == variable) {
            return pair[1];
          }
        }
      }
      if (window.location.hash && window.location.hash != "") {
        let query = window.location.hash.substring(1);
        if (query.indexOf("?") != -1) {
          query = query.substr(query.indexOf("?") + 1);
        }

        let vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
          let pair = vars[i].split("=");
          if (pair[0] == variable) {
            return pair[1];
          }
        }
      }
      return null;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}

.download {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  height: 100vh;
}

.line {
  width: 90%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.header {
  width: 100vw;
}


body {
  margin: 0px;
  overflow-x: hidden;
}

.safariAlert {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  .safari {
    width: 300px;
    height: 125px;
  }

  .containeralert {
    width: 300px;
    height: 80px;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 0px 0px 10px 10px;

    .safariInfo {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      width: 100%;

      .address {
        background: #F3F4F8;
        border-radius: 20px 0px 0px 20px;
        height: 40px;
        width: 200px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: break-all;
        color: #0D0D0D;
      }
    }
  }

  .copy {
    width: 76px;
    height: 40px;
  }
}
</style>
