<template>
    <div style="width: 100vw;">
        <div class="logo">
            <img class="logoimg" src="../assets/logo.png" />
            <div class="info">
                <div style="display: flex;flex-direction: column;">
                    <span style="font-size: 20px;font-weight: bold; color:#0D0D0D;">JalaLive</span>
                    <span style="font-size: 14px;color: #898989;margin-top: 3px;">Global event live broadcast</span>
                </div>
            </div>
        </div>
        <van-swipe class="swipe" :loop="false">
            <van-swipe-item>
                <div class="list">
                    <img src="../assets/android/info.png" class="imginfo1" />
                </div>
            </van-swipe-item>
        </van-swipe>
        <div id="button">Install</div>
        <van-swipe class="swipebanner" :loop="false">
            <van-swipe-item>
                <div class="list">
                    <img src="../assets/android/banner-1.png" class="imginfo" />
                    <img src="../assets/android/banner-2.png" class="imginfo" />
                    <img src="../assets/android/banner-3.png" class="imginfo" />
                    <img src="../assets/android/banner-4.png" class="imginfo" />
                    <img src="../assets/android/banner-5.png" class="imginfo" />
                </div>
            </van-swipe-item>
        </van-swipe>
        <div class="android">
            <img class="pf" src="../assets/android/pf.png" />
            <img src="../assets/line.png" class="lineos" />
            <div class="description">
                <div class="userInfo">
                    <img class="userImg" src="../assets/android/user-1.png" />
                    <div class="userInfoText">
                        <span style="font-size: 14px;color: #0D0D0D;margin-left: 10px;">Adrian 1998</span>
                    </div>
                </div>
                <img src="../assets/android/zan.png" class="zan" />
                <div class="content">
                    Aplikasi siaran olahraga ini luar biasa! Streaming sangat HD dan semuanya gratis. Banyak sekali
                    acara olahraga yang tersedia. Saya sangat menyukainya!
                </div>
            </div>
            <div class="description">
                <div class="userInfo">
                    <img class="userImg" src="../assets/android/user-2.png" />
                    <div class="userInfoText">
                        <span style="font-size: 14px;color: #0D0D0D;margin-left: 10px;">Tom Wijaya</span>
                    </div>
                </div>
                <img src="../assets/android/zan.png" class="zan" />
                <div class="content">
                    Saya sangat puas dengan aplikasi ini, kualitas gambar sangat HD dan gratis. Jenis acaranya sangat
                    lengkap, hampir semua pertandingan olahraga ada. Sangat direkomendasikan!
                </div>
            </div>
            <div class="description">
                <div class="userInfo">
                    <img class="userImg" src="../assets/android/user-3.png" />
                    <div class="userInfoText">
                        <span style="font-size: 14px;color: #0D0D0D;margin-left: 10px;">Husni</span>
                    </div>
                </div>
                <img src="../assets/android/zan.png" class="zan" />
                <div class="content">
                    Aplikasi ini sangat profesional, siaran sangat jernih dan gratis. Semua acara olahraga tersedia,
                    tidak perlu khawatir tidak menemukan pertandingan favorit. Pilihan yang sempurna untuk penggemar
                    olahraga!
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelloWorld',
}
</script>
<style scoped>
.swipe {
    width: 100vw;
    margin-top: 20px;
    margin-bottom: 20px;
}

.list {
    flex-direction: row;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.list::-webkit-scrollbar {
    display: none;
}

.imginfo1 {
    width: auto;
    height: 50px;
}

.swipebanner {
    width: 100vw;
    margin-top: 16px;
    margin-bottom: 16px;
}

.imginfo {
    width: auto;
    height: 125px;
    margin-left: 10px;
}



.logo {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    padding-left: 16px;
}

.logoimg {
    width: 70px;
    object-fit: cover;
    height: 70px;
}

.info {
    height: 70px;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-around;
}


#button {
    margin-left: 20px;
    width: calc(100% - 32px);
    height: 40px;
    background: #3478F6;
    border-radius: 20px;
    color: #FFFFFF;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.lineos {
    width: calc(100% - 32px);
    height: 1px;
    background: #CDCDCD;
    margin-top: 30px;
    margin-bottom: 16px;
}

.android {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.description {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 12px;
    width: calc(100% - 32px);
}

.zan {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 65px;
}

.content {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 12px;
    color: #101010;
    width: calc(100% - 16px);
}

.userInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.userImg {
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.userInfoText {
    font-size: 14px;
    color: #101010;
}

.custom-tabs {
    font-size: 16px;
    font-weight: bold;
    width: 90vw;
    height: 27px;
    margin-top: 15px;
}

.custom-tabs ::v-deep .van-tabs__line {
    width: 15px !important;
    bottom: 20px;
}

.pf {
    width: calc(100% - 40px);
}
</style>