<template>
    <div>
        <img class="header" src="../assets/head.png" />
        <div class="logo">
            <img class="logoimg" src="../assets/logo.png" />
            <div class="info">
                <div style="display: flex;flex-direction: column;">
                    <span style="font-size: 20px;font-weight: bold; color:#0D0D0D;">JalaLive</span>
                    <span style="font-size: 14px;color: #898989;margin-top: 3px;">Global event live broadcast</span>
                </div>
                <div id="button">Install</div>
            </div>
        </div>
        <img src="../assets/line.png" class="line" />
        <img src="../assets/info.png" style="width: 90vw;" />
        <img src="../assets/line.png" class="line" />
        <div class="ios">
            <div class="webclip">
                <div style="font-size: 12px;color: #898989;width: 50vw;">It is recommended to install the anti-drop tag
                    at
                    the same time</div>
                <img src="../assets/ios/sqb.png" style="width: 119px;height: 25px;margin-left: 8px;"
                    @click="onWebClipClick" />
            </div>
            <img src="../assets/line.png" class="lineos" />
            <img src="../assets/ios/text.png" style="width: 60vw;" />
            <van-swipe class="swipe" :loop="false">
                <van-swipe-item>
                    <div class="list">
                        <img class="img" src="../assets/ios/step-1.png" />
                        <img class="img" src="../assets/ios/step-2.png" />
                        <img class="img" src="../assets/ios/step-3.png" />
                        <img class="img" src="../assets/ios/step-4.png" />
                    </div>
                </van-swipe-item>
            </van-swipe>
        </div>
    </div>
</template>

<script>


export default {
    name: 'IosVue',
    props: {
        showWebclip: Function
    },
    setup(props) {
        const onWebClipClick = () => {
            if (props.showWebclip) {
                props.showWebclip();
            }
        }
        return { onWebClipClick }
    }
}
</script>
<style scoped>
.ios {
    display: flex;
    flex-direction: column;
    align-items: center;

    .swipe {
        width: 100vw;
        margin-top: 25px;
        margin-bottom: 25px;

        .list {
            flex-direction: row;
            display: flex;
            align-items: center;
            overflow-x: auto;
            padding-left: 10px;
        }

        .img {
            width: 260px;
            margin-left: 10px;
        }
    }
}

.logo {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    padding-left: 20px;
}

.logoimg {
    width: 113px;
    object-fit: cover;
    height: 113px;
}

.info {
    height: 113px;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-around;
}

#button {
    width: 70px;
    height: 30px;
    background: #3478F6;
    border-radius: 15px;
    color: #FFFFFF;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.webclip {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lineos {
    width: 90%;
    margin-top: 15px;
    margin-bottom: 15px;
}
</style>